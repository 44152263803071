import type { ICasinoPage, ISliderItem } from '~/types';

interface SliderFilterParams {
  slider: ISliderItem[] | undefined;
  isLoggedIn: boolean;
  isTelegramUser: boolean;
  sliderFilterTime: string;
}

export const useSliderFilter = ({ slider = [], isLoggedIn, isTelegramUser, sliderFilterTime }: SliderFilterParams) => {
  const dayjs = useDayjs();

  const filteredSlider = computed(() =>
    slider?.reduce((filteredSliderArr: ICasinoPage['slider'], currentSlide: ISliderItem) => {
      const loggedFilter = (isLoggedIn && currentSlide.loggedHide) || (!isLoggedIn && currentSlide.unloggedHide);

      const telegramFilter = (isTelegramUser && currentSlide.telegramHide) || (!isTelegramUser && currentSlide.webHide);

      let timeFilter = false;

      if (currentSlide.showFromDateTime && currentSlide.showToDateTime) {
        timeFilter = !dayjs(sliderFilterTime).isBetween(
          dayjs(currentSlide.showFromDateTime),
          dayjs(currentSlide.showToDateTime),
          'second'
        );
      } else if (currentSlide.showFromDateTime) {
        timeFilter = !dayjs(sliderFilterTime).isSameOrAfter(dayjs(currentSlide.showFromDateTime), 'second');
      } else if (currentSlide.showToDateTime) {
        timeFilter = !dayjs(sliderFilterTime).isSameOrBefore(dayjs(currentSlide.showToDateTime), 'second');
      }

      if (loggedFilter || timeFilter || telegramFilter) return filteredSliderArr;
      return [...filteredSliderArr, currentSlide];
    }, [])
  );

  return {
    filteredSlider,
  };
};
